@font-face {
  font-family: 'Poppins';
  src: url(fonts/Poppins-Medium.ttf);
}

@font-face {
  font-family: 'Poppins-extra';
  src: url(fonts/Poppins-ExtraBold.ttf);
}

@font-face {
  font-family: 'Poppins-bold';
  src: url(fonts/Poppins-Bold.ttf) ;
  font-weight: 900;
}

@font-face {
  font-family: 'Poppins-black';
  src: url(fonts/Poppins-Black.ttf) ;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.dv_retour {
  padding-top: 20px;
}

button.btn_login {
  width: 100%;
  border-radius: 50px;
  background: var(--primary, #0072EF);
  box-shadow: 0px 10px 25px 0px rgba(0, 114, 239, 0.15);
  padding:11px;
}

button.btn_signup {
  width: 100%;
  border-radius: 50px;
  background: var(--primary, #0072EF);
  box-shadow: 0px 10px 25px 0px rgba(0, 114, 239, 0.15);
  padding:11px;
  margin-top: 25px;
}

small.form-text {
  font-size: 10px;
  font-family: 'POPPINS-BOLD';
  color: #131313;
}

.groupe_check .form-check {
  margin-bottom: 17px;
}

ul.inline_progress {
  list-style-type: none;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: auto;
  padding: 0px!important;
}

.multipleInterest button {
  background: #F3F4F6!important;
  border: 1px #000;
  color: #131313!important;
  border-radius: 1000px;
  margin: 3px;
}

.img_bde {
  width: 126px;
  height: 126px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 57px;
  margin-bottom: 40px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.img_bde img {
  max-width: 100%;
  border-radius: 50%;
  width: 126px;
  height: 126px;
}

span.title_logo {
  font-size: 20px;
  font-family: Poppins-extra;
  color: #0072EF;
  display: block;
  width: 100%;
  margin-top: 19px;
}

.btn {
  font-family: Poppins-bold!important;
  position: relative;
}

img.logo_btn {
  position: absolute;
  left: 9px;
  top: 6px;
  bottom: 0;
}

span.titre_logo {
  font-family: Poppins-extra;
  color: #0072EF;
  font-size: 25px;
}

.inline_logo {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
}

img.logo-customer {
  width: 50px;
  margin-right: 15px;
}

.input-code {
  border: none;
  font-size: 40px;
  text-align: center;
  letter-spacing: 20px;
  font-weight: 500;
  color: #000!important;
}

p, label, button, a, input, select {
  font-family: Poppins;
}

.texte_16 {
  font-size:16px;
  font-family: 16px;
}

.img_confirmation {
  margin-top: 40px;
  margin-bottom: 50px;
}


.multipleInterest button.selected {
  background: #0072EF!important;
  color: #FFF!important;
}

a.black.leftop {
  display: block;
  margin-bottom: 25px;
}

ul.inline_progress li {
  width: 19%;
  height: 2px;
  background: #E5E7EB;
  margin-right: 1%;
}

ul.inline_progress li.current {
  background: #0072EF;
}

.groupe_check {
  margin-top: 30px;
}

.bottom_div {
  position: fixed;
  bottom: 60px;
  padding: 20px;
  left: 0;
  right: 0;
}

.black {
  color:#14142B;
}

.top30 {
  margin-top: 30px!important;
}

h2.titre_h2 {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 30px;
  margin-bottom: 15px;
  font-family: Poppins-extra;
}

p.txt_or {
  font-weight: 900;
  color: #9CA3AF;
  margin-top: 25px;
  font-size: 16px;
  position:relative;
}

button.btn_maso {
  background: none;
  border: none;
  position: absolute;
  right: 9px;
  top: 0;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}

a.black.droite {
  text-align: right;
  position: absolute;
  right: 11px;
  color: #0072EF;
}

img.eye_icon {
  width: 25px;
}

p.title_pop {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

svg.blue_check path {
  fill: #0072ef;
}

svg.error path {
  fill: #FF0000;
}

svg.blue_check {
  transform: scale(2.6);
  margin-bottom: 38px;
  margin-top: 23px;
}

button.btn_ok {
  background: #0072EF;
  border-radius: 30px;
  padding-left: 35px;
  padding-right: 35px;
}

.success_div {
  border-radius: 16px;
  background: #FFF;
  box-shadow: 2px 1px 1px 2px #e1dfdf;
  position: absolute;
  top: 15%;
  margin: 24px;
  padding: 20px;
  text-align: center;
}

.inline_input.maso {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inline_input.maso {
  display: flex;
  justify-content: space-between;
}

img.logo_budease {
  max-width: 304px;
}

.bottom_button {
  margin-top: 140px;
}

.div_logo {
  text-align: center;
  padding: 43px;
}

p.txt_or::before {
  content: '';
  width: 40%;
  height: 1px;
  background: #E5E7EB;
  display: block;
  position: absolute;
  top: 12px;
  left: 0;
}

button.btn_google {
  border-radius: 24px;
  border: 1px solid #D1D5DB;
  background: var(--white, #FFF);
  width: 100%;
  color: #131313;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.07px;
  padding: 11px;
  margin-bottom: 19px;
}

button.btn_facebook {
  border-radius: 24px;
  border: 1px solid #D1D5DB;
  background: var(--white, #FFF);
  width: 100%;
  color: #131313;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.07px;
  padding: 11px;
  margin-bottom: 19px;
}

ul.inline_progress.event {
  margin-top: 30px;
  margin-bottom: 30px;
}

a.black {
  text-decoration: none;
}

ul.inline_progress.event li {
  width: 33%;
  height: 3px;
}

.conteneur {
  min-height: 100vh;
  padding-bottom: 50px;
}

.bottom_txt {
  position: absolute;
  padding:20px;
  bottom: 0;
  left: 0;
  right: 0;
  color: #6B7280;
  font-size: 14px;
}

.bottom_txt a {
  color: #0072EF;
  font-weight: bold;
}

p.txt_or::after {
  content: '';
  width: 40%;
  height: 1px;
  background: #E5E7EB;
  display: block;
  position: absolute;
  top: 12px;
  right: 0;
}

.pad20 {
  padding:20px;
  padding-bottom:0px;
  padding-top:0px;
}

.menu_bottom_mobile {
  background: #FFF;
  box-shadow: 0px 10px 25px 0px rgba(1, 42, 87, 0.15);
}

.input-rond {
  border-radius: 20px!important;
}

.select-bde .css-13cymwt-control {
  border-radius: 20px!important;
  padding: 8px;
  margin-top: 38px;
}

button.btn_noborder {
  background: none;
  color: #0072EF;
  border: none;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
}

.first_div {
  width: 50%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0072EF;
  position: relative;
}

.second_div {
  width: 50%;
  height: 100vh;
  padding: 25px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position:relative;
}

.bottom_submit {
  position: absolute;
  bottom: 100px;
  left: 30px;
  right: 30px;
}

.a_recommended.bde {
    border-radius: 8px;
    box-shadow: 0px 10px 25px 0px rgba(1, 42, 87, 0.15);
}

ul.mb-3.nav.nav-tabs {
  border-bottom: 1px solid #6B7280;
}

ul.nav-tabs li button.active::after {
  content: '';
  width: 100%;
  height: 3px;
  background: #0072EF;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 0;
}

ul.nav-tabs li button {
    position: relative;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}

ul.nav-tabs li button.active {
  color: #0072EF!important;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.07px;
}

ul.nav-tabs li button {
  border: none;
  width: 100%;
  color: #131313;
}

.list_approved_event {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 27px;
}

.bottom_submit_sign {
  left: 30px;
  right: 30px;
}

img.congrat_img {
  margin-bottom: 35px;
  margin-top: 35px;
}

.img-connected {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 15px;
    margin-right: 15px;
}

ul.ul_bottom {
  list-style-type: none;
  padding-left:0px;
}

.backwhite {
  background-color: #FFF;
}

.colonne_deux {
  display: flex;
}

span.title_board {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #4B5563;
  display: block;
  margin-bottom: 38px;
  margin-top: 25px;
}

ul.menu_customer.accordeon li a {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #4B5563;
}

ul.menu_customer.accordeon li {
  margin-bottom: 25px;
}

svg.fleche_reverse {
  position: absolute;
  right: 2px;
  top: 1px;
}

.parent_accordeon h4 {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #4B5563;
  margin-bottom: 20px;
  position: relative;
}

h4.accorded {
  color: #0072EF;
}

select.select_order {
  width: 125px;
  border: none;
  margin-left: 6px;
}

select.select_ronde {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.07px;
  color: #131313;
  border-radius: 25px;
  width: auto;
  margin-left: 15px;
}

.leftbar {
  background: #FFF;
  box-shadow: 1px 2px 3px #e7e6e6;
  width:17%;
  margin-right:3px;
  position: relative;
}

.bottom_logout::before {
  content: '';
  width: 80%;
  height: 1px;
  background: #E5E7EB;
  display: block;
  position: absolute;
  top: 0;
}

img.img_cloche {
  margin-right: 20px;
}

.parent_nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 13px;
}

.contentbar {
  background: #F3F4F6;
  width: 83%;
}

ul.menu_customer li.active {
  background: #0072EF;
  padding: 13px;
  border-radius: 12px;
}

ul.menu_customer li.active a {
  color:#FFF!important;
}

ul.ul_bottom li {
  margin-bottom: 30px;
}

ul.ul_bottom li a img {
  margin-right: 15px;
}

ul.menu_customer {
  list-style-type: none;
  padding-left:0px;
}

.inline_input {
  position: relative;
}

.p-datatable-wrapper {
  padding-top: 0px;
  background: #FFF;
  border-radius: 10px;
  padding-bottom: 40px;
}

table.p-datatable-table {
  background: #FFF;
  border-radius: 8px;
  padding: 20px;
}

.div_table {
  padding-top: 30px;
  background: #FFF;
  border-radius: 10px;
}

span.table_title {
  color: var(--Bud_Black, #0A0A0B);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: block;
  margin-bottom: 30px;
  margin-left: 20px;
}

.p-paginator.p-component {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
}

.pagination_div {
  position: relative;
  padding:20px;
}

button.p-paginator-first {
  position: absolute;
  left: 8px;
  bottom: 20px;
  border-radius: 8px;
  border-radius: 8px;
  border: 1px solid var(--gray-200, #E5E7EB);
  background: var(--White, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 5px;
  color: #6B7280;
  padding-left: 15px;
  padding-right: 15px;
}

button.p-paginator-last {
  position: absolute;
  right: 8px;
  bottom: 20px;
  border-radius: 8px;
  border: 1px solid var(--gray-200, #E5E7EB);
  background: var(--White, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 5px;
  color: #6B7280;
  padding-left: 15px;
  padding-right: 15px;
}

button.p-paginator-page {
  color: var(--gray-500, #667085);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border: none;
  margin-left: 10px;
  padding: 6px 15px;
  border-radius: 8px;
  background: #FFF;
}

.a_recommended.event {
  border-radius: 0px 16px 16px 0px;
  background: var(--gray-100, #F3F4F6);
}

.event_created {
  border-radius: 16px;
  background: #FFF;
  padding-top: 23px;
  padding-left: 20px;
  padding-bottom: 15px;
  margin-bottom: 20px;
}

.photo_profil img {
  max-width: 100%;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center;
}

span.etudiant_name {
  color: var(--Bud_Black, #0A0A0B);
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

span.value_etudiant {
  color: var(--Black, #131313);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
  margin-bottom: 10px;
  margin-top: 5px;
}

.profil_groupe {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.photo_profil {
  width: 110px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left_side {
  padding-top: 32px;
  border-radius: 16px;
  background: #FFF;
}


span.lieu_school {
  color: #9CA3AF;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.school_div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
}

span.nom_school {
  color: var(--Bud_Black, #0A0A0B);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: block;
}

img.img_school {
  background: #E5E7EB;
  width: 45px;
  height: 45px;
  padding: 10px;
  border-radius: 50%;
  margin-right: 15px;
}

.texte_20 {
  color:#0A0A0B;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

h3.texte_20 {
  margin-top: 30px;
  margin-bottom: 20px;
}

ul.ul_interet {
  list-style-type: none;
  padding-left: 0px;
  display: flex;
  flex-wrap: wrap;
}

.marge {
  margin-bottom: 30px;
  margin-top: 9px;
}

ul.ul_interet li {
  color: var(--Black, #131313);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.06px;
  margin: 6px;
  border-radius: 1000px;
  background: var(--gray-100, #F3F4F6);
  padding: 6px;
  padding-left: 13px;
  padding-right: 13px;
}

span.label_etudiant {
  color:#6B7280;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
  margin-top: 20px;
}

button.p-paginator-page.p-highlight {
  border-radius: 8px;
  background: rgba(0, 114, 239, 0.10);
}

button.p-paginator-prev {
  border: 1px solid #E5E7EB;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 5px;
  color: #6B7280;
  border-radius: 8px;
  margin-right: 14px;
}

button.p-paginator-next {
  border: 1px solid #E5E7EB;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 5px;
  color: #6B7280;
  border-radius: 8px;
  margin-left: 14px;
}

table.p-datatable-table  td {
  color: #0A0A0B;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px;
}

table.p-datatable-table tr {
  border-bottom: 1px solid var(--gray-200, #E5E7EB);
}

thead.p-datatable-thead th {
  color: var(--Bud_Black, #0A0A0B);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px;
}

span.p-column-title {
  margin-right: 5px;
}

.groupe_forme {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

thead.p-datatable-thead {
  border-top: 1px solid #E5E7EB;
  border-bottom: 1px solid #E5E7EB;
  background: #F9FAFB;
}

.filtre_activite {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.groupe_droite {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

button#btn_map {
  display: flex;
  background: #FFF;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
}

button#btn_filter_list {
  display: flex;
  background: #0072EF;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
}

button#btn_create {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 12px;
  background: #0072EF;
  margin-left: 15px;
}

.separator {
  background: #E5E7EB;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
}

button#btn_filter {
  background: none;
  border-radius: 50%;
  border: 1px solid #D1D5DB;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

span.user_connected {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #0A0A0B;
}

h2.back_h2 {
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #0A0A0B;
}

img.loupe {
  position: absolute;
  top: 5px;
  left: 8px;
  opacity: 0.8;
}

input.placeholdermarge::placeholder {
  padding-left: 30px;
}

.padding10 {
  padding: 25px;
}

ul.ul_bottom li a {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #4B5563;
  text-decoration: none;
}

ul.menu_customer li a {
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #4B5563;
  text-decoration: none;
}

h3.h3_back {
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #0A0A0B;
}

.a_event {
  width: 235px;
  height: 300px;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 15px;
    margin-right: 15px;
}

.img_recommend img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

span.title_recommend {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #131313;
  display: block;
}

span.lieu_recommend {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
  color: #131313;
}

span.price_recommend {
  color: var(--gray-400, #9CA3AF);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: line-through;
}

span.date_recommend {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #131313;
  display: block;
}

span.price_paid {
  color: var(--Black, #131313);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 10px;
}

.desc_recommend {
  width: 235px;
  padding-left: 18px;
}

.a_recommended {
    background: #FFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 16px;
    width: 350px;
    justify-content: space-between;
    max-width: 100%;
    margin-bottom: 25px;
}

button.btn_submit_bo {
  background: #0072EF;
  color: #FFF;
  font-family: 'Poppins';
  font-size: 16px;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
}

.event_recommanded {
  margin-top: 30px;
}

.img_recommend {
  width: 115px;
  height: 115px;
  overflow: hidden;
  border-radius: 16px 0px 0px 16px;
}

img.event_couv {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: relative;
  z-index: 100;
}

span.a_date {
  display: block;
  z-index: 200;
  position: absolute;
  top: 14px;
  left: 12px;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #FFF;
  border-radius: 15px;
  background: rgba(138, 138, 138, 0.25);
  backdrop-filter: blur(2px);
  padding: 5px;
  padding-left: 13px;
  padding-right: 13px;
}

.desc_event {
  z-index: 200;
  position: absolute;
  font-family: Poppins;
  bottom: 8px;
  color: #FFF;
  left: 0;
    right: 0;
    padding: 12px;
}

.grid_event {
  display: flex;
  margin-top: 30px;
  justify-content: start;
  flex-wrap: wrap;
}

span.title_event {
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #FFF;
  display: block;
}

span.price_event {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: line-through;
  color: #9CA3AF;
}
span.price_reel {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #FFF;
  margin-left: 10px;
}
span.lieu_event {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #FFF;
  display: block;
}

ul.menu_customer li {
  margin-bottom: 30px;
}

ul.menu_customer li img {
  margin-right: 15px;
}

.bottom_logout {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  padding: 27px;
}

.parent_menu {
  height: 100vh;
  padding: 25px;
}

.img-connected img {
  max-width: 100%;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: center;
}

.flex_start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.flex_divider {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.content {
  padding:20px;
}
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

span.slogan_title {
  display: block;
  font-size: 70px;
  font-family: 'Poppins-extra';
  color: #FFF;
  padding-top: 30px;
}

.a_recommended.marker {
  box-shadow: 1px 1px 1px 1px #ccc;
}

.groupe_droie {
  position: absolute;
  top: 20px;
  right: 20px;
}

span.date_event_detail {
  color: #FFF;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
}

span.title_event_detail {
  color: #FFF;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: block;
  margin-bottom: 12px;
}

.groupe_info {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.description_detail {
  padding: 20px;
}

.subtitle {
  color: var(--gray-500, #6B7280);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
  margin-bottom: 15px;
}

.legendspan {
  color: var(--gray-500, #6B7280);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tarif_nom {
  color: var(--Bud_Black, #0A0A0B);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 15px;
}

p.detail_tarif {
  display: inline-flex;
  align-items: center;
}

.icone_navigation_right {
  position: fixed;
  bottom: 193px;
  z-index: 9999;
  right: 12px;
}

.groupe_inline_bouton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button.btn_gris {
  border-radius: 50px;
  background: var(--gray-200, #E5E7EB);
  width: 100%;
  margin-right: 10px;
  padding: 11px;
  color: #131313;
  border: 1px solid #E5E7EB;
}

.groupe_tarif {
  padding-bottom: 20px;
  border-bottom: 1px dotted #131313;
  margin-bottom: 26px;
}

a.btn_create_event {
    font-size: 45px;
    color: #0072EF;
    background: #FFF;
    border-radius: 50%;
    width: 45px;
    height: 45px!important;
    display: flex;
}

button.btn_rond {
  border-radius: 50%;
  background: rgba(10, 10, 11, 0.05);
  width: 35px;
  height: 35px;
  border: none;
  margin-right: 3px;
}

.groupe_calcul_tarif {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

button.bt_date {
  background: #FFF;
  color: #0d6efd;
  width: 100%;
}

button.btn_add {
    width: 100%;
    background: none;
    color: #0072EF;
    border: 1px dotted #0072EF;
    border-radius: 25px;
}

span#prixTotal {
  font-family: 'Poppins-bold';
  font-size: 18px;
}

.montat_tarif {
  margin-top: 5px;
  font-family: 'Poppins-bold';
}

button.btn_rondplus {
  border-radius: 50%;
  background:#0072EF;
  width: 35px;
  height: 35px;
  border: none;
  margin-left: 3px;
}

.span_pers {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.408px;
  margin-right: 6px;
  margin-left: 6px;
}

p.separateur_point {
  border-top: 1px dotted #131313;
}

.groupe_total {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.groupe_prix_tarif {
  display: inline-flex;
  justify-content: space-between;
  align-items: flex-start;
  width:100%;
}

.groupe_nom_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

input#nombre_personne {
  width: 54px;
}

span.prix_personne {
  color: var(--gray-500, #6B7280);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 10px;
}

.legendlabel {
  color: var(--gray-500, #6B7280);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
}
p.separation {
  background: #F3F4F6;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
}



.spancontent {
  color: var(--gray-500, #131313);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
  margin-bottom: 15px;
}

span.reduction_prix {
  color: var(--Black, #131313);
  text-align: center;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.05px;
  border-radius: 1000px;
  background: var(--Secondary, #F2CB56);
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

span.prix_normal {
  color: var(--Black, #131313);
  font-family: 'Poppins-extra';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

span.barree {
  color: var(--gray-500, #6B7280);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: line-through;
  margin-right: 13px;
}

label.label_check {
  color: #131313!important;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.groupe_price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

span.sponsor {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

img.rounded {
  background: #e5e5e5;
  border-radius: 50%!important;
  margin-right: 15px;
  padding: 8px;
}

.texte_14 {
  color: var(--gray-500, #131313);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

a.btn_reserve {
  color: #FFF;
  background: #0072EF;
  padding: 6px;
  border-radius: 22px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  padding-left: 15px;
  padding-right: 15px;
}

.groupe_droie a {
  margin-right: 18px;
}

.couverture_detail {
  padding: 20px;
  position:relative;
}

.full.container {
  width: 100%;
  max-width: 100%;
}

.leaflet-container a.leaflet-popup-close-button {
  color: #171F26!important;
}

.leaflet-popup-content-wrapper {
  /* background: transparent!important; */
  /* box-shadow: none!important; */
}

.top20 {
  margin-top:20px;
}

.carousel_event_recommended .swiper-vertical .swiper-slide {
  height: 121px!important;
}

.carousel_event_recommended .a_recommended {
  box-shadow: 1px 1px 1px 1px #eff0f1;
}

.full {
  width: 100%;
  margin-left: 0px!important;
  margin-right: 0px!important;
  padding-left: 0px!important;
  padding-right: 0px!important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button.btn_upload {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #F3F4F6;
  border: 1px solid #F3F4F6;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

img.camera {
  position: absolute;
  width: 40px;
  bottom: 0;
}

ul.ul_puce li {
  color: var(--Bud_Black, #0A0A0B);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.08px;
  margin-bottom: 7px;
}

.width50 {
  width: 49%!important;
}

.mobile_div {
  height: 100vh;
  padding: 25px;
}

span.text_logo {
  display: block;
  width: 100%;
  text-align: center;
  color: #0072EF;
  font-family: 'Poppins-extra';
  font-size: 22px;
  margin-top: 26px;
}

.div_logo_mobile {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 39px;
}

button.btn_upload_doc {
  border-radius: 16px;
  border: 1px dashed var(--gray-300, #D1D5DB);
  background: #FFF;
  width: 100%;
  color: #131313;
  height: 200px;
  margin-top: 29px;
  margin-bottom: 29px;
}

@media(max-width:980px) {
  .flex_divider {
    flex-wrap: wrap;
  }

  img.logo_budease {
      max-width: 104px;
      margin-top: 35px;
  }

  span.slogan_title {
      display: block;
      font-size: 39px;
      font-family: 'Poppins-extra';
      color: #FFF;
      padding-top: 16px;
  }

  .first_div {
      width: 100%;
      height: 224px;
      position: relative;
  }

  .dv_retour {
      padding-top: 20px;
      width: 100%;
  }

  .second_div {
    flex-direction: column;
    padding: 25px 40px;
    width:100%;
  }
}

.loading{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.img_couverture{
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.btn-validate{
  text-align: center;
  margin-top: 5px;
}
.btn-validate button{
  border: 0px solid;
  padding: 8px;
  background-color:#0d6efd;
  color: white;
  border-radius: 10px;
}
.lieu{
  font-size: 14px;
}

.input-adresse{
  height: 100px;
}