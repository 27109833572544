ul.item_menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0px!important;
}

.menu_bottom_mobile {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    left: 0;
    right: 0;
    z-index: 300;
    background: #FFF;
    padding-top: 20px;
}

ul.item_menu li {
    list-style-type: none;
}

.icone_navigation {
    position: fixed;
    bottom: 129px;
    z-index: 500;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 22px;
}



.icone_navigation a.current svg path {
    stroke: #FFF;
}

.icone_navigation a.current {
    background: #0072EF;
}

.icone_navigation a {
    background: #FFF;
    padding: 10px;
    border-radius: 50%;
    margin-bottom: 10px;
}

ul.item_menu li img {
    width: 30px;
    display: block;
}

ul.item_menu li {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: inherit;
}

ul.item_menu span {
    color: #0A0A0B;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: block;
    margin-top: 17px;
}

ul.item_menu li.active span {
    color: #0072EF;
}

ul.item_menu li a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.top_bar {
    padding: 15px;
    padding-top: 30px;
    padding-bottom: 30px;
    box-shadow: 0px 2px 10px #dddbdb;
}

.carousel_categorie {
    margin-top: 30px;
}

form.form_student_activity {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    padding-top:0px;
}

.carousel_event .swiper-slide {
    width: 243px!important;
}